import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../shared/config.data';
import { RegistrationSettingsDetail, UserRegisterValidateCommand, RegisterRequest, MinorRegisterValidateCommand } from '../../shared/models';
import { Observable } from 'rxjs';


@Injectable()
export class RegisterService {
  baseUrl: string;
  userId: number;
  constructor(protected _http: HttpClient, private _configuration: ConfigurationService) {
    this.baseUrl = _configuration.getStaffApi();
    this.userId = _configuration.getUserId();
  }

  public getRegistrationSettings(): Observable<RegistrationSettingsDetail> {
    let actionUrl = `${this.baseUrl}userregistration/settings`;
    return this._http.get<RegistrationSettingsDetail>(actionUrl);
  }
  public validateCredentials(request: UserRegisterValidateCommand): Observable<boolean> {
    let actionUrl = `${this.baseUrl}userregistration/validate`;
    return this._http.post<boolean>(actionUrl, request);
  }
  public createAccount(request: RegisterRequest): Observable<number> {
    let actionUrl = `${this.baseUrl}userregistration`;
    return this._http.post<number>(actionUrl, request);
  }
}
