import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../core/authentication/auth.service';
import { RegisterRequest, RegistrationSettingsDetail, HearAboutUs } from '../../shared/models';
import { RegisterService } from './register.service';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from '../../shared/lookup.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../../shared/config.data';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  success: boolean = false;
  isMinor: boolean = false;
  submitted: boolean = false;
  isLoading: boolean = false;

  error: string;
  states: string[];

  step: number;
  hearAboutUs: number;
  isMale?: number = null;

  showUser: number = 1;
  showAddress: number = 2;
  showConfirmation: number = 3;

  friend: number = HearAboutUs.Friend;
  organization: number = HearAboutUs.Organization;

  registerRequest: RegisterRequest;
  registerSettings: RegistrationSettingsDetail;

  constructor(private authService: AuthService,

    private spinner: NgxSpinnerService,
    private registerService: RegisterService,
    private toastr: ToastrService,
    private lookupService: LookupService,
    private route: ActivatedRoute,

    private config: ConfigurationService) {

    this.step = this.showUser;
    this.registerRequest = new RegisterRequest();
    this.registerRequest.redirectUrl = window.location.origin;
    this.states = this.lookupService.getStates();
  }

  ngOnInit() {

    this.spinner.show();
    if ("" != this.config.getConfirmJs()) {
      const script = document.createElement('script');
      script.src = this.config.getConfirmJs();
      document.body.appendChild(script);
    }
    this.route.queryParams.subscribe(params => {
      this.registerRequest.referralId = params['key'];
    });
    this.registerService.getRegistrationSettings().subscribe((val) => {
      this.registerSettings = val;
      this.spinner.hide();
    });

  }

  login() {
    this.authService.login();
  }

  validate(): boolean {
    if (this.registerRequest.userName === undefined || this.registerRequest.userName === null) {
      this.toastr.error("Please provide a user name of at least 6 characters");
      return false;
    } else {
      this.registerRequest.userName = this.registerRequest.userName.trim();
      if (6 > this.registerRequest.userName.length) {
        this.toastr.error("Please provide a user name of at least 6 characters");
        return false;
      }
      if (this.registerRequest.userName.indexOf(' ') >= 0) {
        this.toastr.warning("Username cannot have spaces");
        return false;
      }
    }
    if (this.isMale === undefined || this.isMale === null) {
      this.isMale = null;
      this.toastr.error("Must choose a gender");
      return false;
    }
    if (this.registerRequest.isMinor()) {
      this.isMinor = true;
      if (this.registerRequest.parentUserName === undefined || this.registerRequest.parentUserName === null) {

        this.registerRequest.parentUserName = null;
        this.toastr.error("Minors must register with parental permission. Please enter a valid USERNAME of a parent.");
        return false;
      } else {
        this.registerRequest.parentUserName = this.registerRequest.parentUserName.trim()
        if (1 > this.registerRequest.parentUserName.length) {

          this.registerRequest.parentUserName = null;
          this.toastr.error("Minors must register with parental permission. Please enter a valid USERNAME of a parent.");
          return false;
        }
      }
    } else {
      this.isMinor = false;
      this.registerRequest.parentUserName = null;
    }
    return true;
  }

  validateCredentials() {

    let validated = this.validate();

    if (validated) {

      this.spinner.show();
      this.isLoading = true;

      this.registerService.validateCredentials({
        email: this.registerRequest.email,
        firstName: this.registerRequest.firstName,
        lastName: this.registerRequest.lastName,
        password: this.registerRequest.password,
        userName: this.registerRequest.userName,
        dateOfBirth: this.registerRequest.dateOfBirth,
        parentUserName: this.registerRequest.parentUserName,
        confirmPassword: this.registerRequest.confirmPassword

      }).subscribe(_result => {

        this.spinner.hide();
        this.isLoading = false;

        this.step = this.showAddress;

      }, error => {

        this.spinner.hide();
        this.isLoading = false;

        if (error.status === 400) {
          this.toastr.error(error.error.message);
        } else {
          this.toastr.error("Error occured");
        }
      });
    } else {
      this.registerRequest.parentUserName = null;
    }
  }

  back(stepNumber: number) {
    this.step = stepNumber - 1;
  }

  onSubmit() {

    let validated = this.IsValid();

    if (validated) {

      this.spinner.show();
      this.isLoading = true;

      if (this.isMale === undefined || this.isMale === null) {
        this.registerRequest.isMale = null;
      } else {
        this.registerRequest.isMale = Number(this.isMale);
      }

      if (this.hearAboutUs == this.friend) {
        this.registerRequest.organizationId = null;
      } else if (this.hearAboutUs == this.organization) {
        this.registerRequest.referralName = null;
      }

      this.registerRequest.redirectUrl = window.location.origin + "/profile";

      this.registerService.createAccount(
        this.registerRequest
      ).subscribe(_response => {

        this.spinner.hide();
        this.isLoading = false;

        this.step = this.showConfirmation;
        this.toastr.success("Account created! Please check your email to validate");

      }, error => {

        this.spinner.hide();
        this.isLoading = false;

        if (error.status === 400) {
          this.toastr.error(error.error.message);
        } else {
          this.toastr.error("Error occured");
        }
      });
    }
  }

  IsValid(): boolean {

    if (this.registerRequest.hearAboutUsId == this.friend && !this.registerRequest.referralName) {
      this.toastr.warning("Please provide a referral name");
      return false;
    }

    if ((this.registerRequest.address1 === undefined || this.registerRequest.address1 === null)
      || 1 > this.registerRequest.address1.trim().length) {
      this.toastr.warning("Please provide an address");
      return false;
    }

    if ((this.registerRequest.city === undefined || this.registerRequest.city === null)
      || 1 > this.registerRequest.city.trim().length) {
      this.toastr.warning("Please provide a city");
      return false;
    }

    if ((this.registerRequest.stateAbbreviation === undefined || this.registerRequest.stateAbbreviation === null)
      || 1 > this.registerRequest.stateAbbreviation.trim().length) {
      this.toastr.warning("Please provide a state");
      return false;
    }

    if ((this.registerRequest.zipCode === undefined || this.registerRequest.zipCode === null)
      || 1 > this.registerRequest.zipCode.trim().length) {
      this.toastr.warning("Please provide a zip code");
      return false;
    }

    let regex = "\\d{10}";

    if (this.registerRequest.cellPhone) {
      if (null != this.registerRequest.cellPhone && 1 < this.registerRequest.cellPhone.length) {

        this.registerRequest.cellPhone = this.registerRequest.cellPhone.replace(/\D/g, '')

        if (null != this.registerRequest.cellPhone && 1 < this.registerRequest.cellPhone.length) {
          if (!this.registerRequest.cellPhone.match(regex)) {
            this.toastr.warning("Cell Phone is not valid; must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }

    if (this.registerRequest.homePhone) {
      if (null != this.registerRequest.homePhone && 1 < this.registerRequest.homePhone.length) {

        this.registerRequest.homePhone = this.registerRequest.homePhone.replace(/\D/g, '')

        if (null != this.registerRequest.homePhone && 1 < this.registerRequest.homePhone.length) {
          if (!this.registerRequest.homePhone.match(regex)) {
            this.toastr.warning("Home Phone is not valid; must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }

    if (this.registerRequest.workPhone) {
      if (null != this.registerRequest.workPhone && 1 < this.registerRequest.workPhone.length) {

        this.registerRequest.workPhone = this.registerRequest.workPhone.replace(/\D/g, '')

        if (null != this.registerRequest.workPhone && 1 < this.registerRequest.workPhone.length) {
          if (!this.registerRequest.workPhone.match(regex)) {
            this.toastr.warning("Work Phone is not valid; must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }

    return true;
  }
}
